import React from 'react'
import './footer.css'
const Footer = () => {
  return (
   <footer className="footer">
    <div className="footer__container container">
      <h2 className="footer__title">MunirYounos - Portfolio Website</h2>
      <ul className="footer__list">
          <li className="footer__li">
              <a href="#home" className="footer__link">Home</a>
          </li>

          <li className="footer__li">
              <a href="#about" className="footer__link">About</a>
          </li>

          <li className="footer__li">
              <a href="#skills" className="footer__link">Skills</a>
          </li>

          <li className="footer__li">
              <a href="#services" className="footer__link">Services</a>
          </li>
          <li className="footer__li">
              <a href="#portfolio" className="footer__link">Portfolio</a>
          </li>
          <li className="footer__li">
              <a href="#qualification" className="footer__link">Qualifications</a>
          </li>
          <li className="footer__li">
              <a href="#contact" className="footer__link">Contact</a>
          </li>
      </ul>

        <div className="footer__social">
          {/* <a href="https://instagram.com" className="footer__social-link" rel="noreferrer" target="_blank">
            <i className="uil uil-instagram"></i>
          </a> */}
          <a href="https://www.linkedin.com/in/munir-younos/" className="footer__social-link" rel="noreferrer" target="_blank">
            <i className="uil uil-linkedin"></i>
          </a>
          <a href="https://github.com/MunirYounos" className="footer__social-link" rel="noreferrer" target="_blank">
            <i className="uil uil-github-alt"></i>
          </a>
        </div>
    <span className="footer__copy">&#169; MunirYounos Portfolio. All rights reserved. </span>
    </div>
    </footer>
  )
}

export default Footer