import React from 'react'
import Works from './Works';
import './work.css';

const Work = () => {
  return (
    <section className="work section" id="portfolio">
      <div className="container">
      <h2 className="section__title">Projects</h2>
      <span className="section__subtitle">Most recent projects</span>
      <Works />
      </div>
    </section>
  )
}

export default Work