import anodyneWebshop from "../../assets/anodyne.jpg";
import ajrWebshop from "../../assets/ajrcollection.jpg";
import vonfritzWebshop from "../../assets/vonfritz.jpg";
import anodyneBanner from "../../assets/banner-anodyne.jpg";
// import anodyneBanner2 from "../../assets/banner4.jpg"
import anodyne3d from "../../assets/anodyne-3d.jpg"
import posture1 from "../../assets/posture1.jpg"
import posture2 from "../../assets/posture2.jpg"
import wpmain from "../../assets/wpmain.jpg"
import underwear from "../../assets/3d-underwear.jpg"
import airpods from "../../assets/airpods-pro.jpg"
import shopify from "../../assets/procover.jpg";
import wordpress from "../../assets/wordpress.jpg";
import react from "../../assets/react.jpg";
import design from "../../assets/design.jpg";
import ps from "../../assets/ps.jpg";
import ai from "../../assets/ai.jpg"
import logoDesign from "../../assets/logo.jpg"


export const projectsList = [
{
id: 1,
cover: shopify,
image: anodyneWebshop,
title: 'Anodyne webshop design - Shopify',
description: 'The intuitive navigation ensures effortless exploration, guiding users through the content seamlessly. Each page is meticulously crafted to deliver valuable information and engage visitors with captivating visuals. ',
category: 'web',
pageUrl: 'https://anodyne.dk'
},
{
id: 2,
cover: shopify,
image: ajrWebshop,
title: 'AJRCollection webshop designed and implemented in - Shopify',
description: "The features I implemented in AJR Collection webshop, collectively enhance the AJR Collection website, providing fans with a seamless and immersive online experience that celebrates the brand's quality and effectiveness.",
category: 'web',
pageUrl: 'https://ajrcollection.com'
},
{
id: 3,
cover: shopify,
image: vonfritzWebshop,
title: 'Vonfritz Aps webshop designed and implementation - Shopify',
description: 'The website is built with a responsive design, ensuring that it looks and functions flawlessly across various devices and screen sizes. Users can access the site from desktops, laptops, tablets, and smartphones, experiencing consistent performance and optimal readability.',
category: 'web',
pageUrl: 'https://vonfritz.com'
},
{
id: 4,
cover: ps,
image: anodyneBanner,
title: 'Black Friday Bannder Designs - Anodyne',
description: 'This banner is designed using Adobe Illustrator and Adobe Photoshop, for webshop\'s front page.',
category: 'design'
},
{
id: 5,
cover: ai,
image: anodyne3d,
title: 'Posture shirt 3D Illustration - Anodyne',
description: 'This 3D illustrations is designed using Adobe Illustrator and Adobe Photoshop, for webshop\'s front page.',
category: 'design'
},
{
id: 6,
cover: wordpress,
image: posture1,
title: 'Posture shirt Wordpress Website - Anodyne',
category: 'web',
pageUrl: 'https://postureshirt.dk/'
},
{
id: 7,
cover: wordpress,
image: posture2,
title: 'Posture shirt Wordpress Website - Anodyne',
category: 'web',
pageUrl: 'https://posture-shop.dk/'
},
{
id: 8,
cover: wordpress,
image: wpmain,
title: 'Wordpress main multi-website',
category: 'web',
pageUrl: 'https://anodyne-shop.com/'
},
{
id: 9,
cover: ps,
image: underwear,
title: 'AJR 3D Illustration - Anodyne',
description: 'This 3D illustrations is designed using Adobe Photoshop, for AJR website\'s front page on both mobile and desktop devices.',
category: 'design'
},
{
id: 10,
cover:  react,
image: airpods,
title: 'Airpods website - React website',
description: 'The website is built in reactJs. The website have hero section, slider section and product section. visist the website on this netlify link.',
category: 'web',
pageUrl: 'https://airpodlandinpage.netlify.app/'
},
{
id: 11,
cover:  design,
title: 'Figma design',
description: 'The landing page is build using Figma. Landing page has hero section with panoramice image and text contents.',
category: 'design',
pageUrl: 'https://www.behance.net/gallery/197789291/Tour-landing-page-concept-draft'
},
{
id: 12,
cover:  design,
title: 'Figma Workout clone',
description: 'The landing page is cloned using Figma. I recreated/cloned this website using Figma to enhace my Figma design skills.',
category: 'design',
pageUrl: 'https://www.behance.net/gallery/82008419/Figma-real-clone'
},
{
id: 13,
cover:  design,
title: 'Figma Property dealer website clone',
description: 'The landing page is cloned using Figma. I recreated/cloned this website using Figma to enhace my Figma design skills.',
category: 'design',
pageUrl: 'https://www.behance.net/gallery/197793741/Property-dealer-website-concept-design'
},
{
 id: 14,
cover:  ai,
image: logoDesign,
title: 'Logo concept design for letter M',
description: 'The concept start from sketching the mountain and a circle, and the letter M. It provide a nice and decent M letter logo. see a zoomed version of it by clicking on zoom + button.',
category: 'design'
},
{
id: 15,
cover:  design,
title: 'Figma design',
description: 'The landing page is build using Figma. The purple color give the design a good character and all design features live according to design theories and color dynamics.',
category: 'design',
pageUrl: 'https://www.behance.net/gallery/198138021/Design-agency-website-UI'
},
]

export const projectsCategory = [
{
name: 'all',
},
{
name: 'web',
},
{
name: 'design',
}
]