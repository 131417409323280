import Kenneth from '../../assets/kenneth.webp';
import Anne from '../../assets/anne.webp';
import Art from '../../assets/art.jpg';


export const Data = [
{
  id: 1,
  image: Kenneth,
  name: "Kenneth Avnsted",
  description: "Munir has worked on many different projects and has proven to be extremely good at restructuring and adapting to the chaotic and ever-changing work life in a start-up world. In particular, Munir has shown his many skills during the Webshops switch from SmartWeb to Shopify, where he was responsible for migrating all of the company's 12 webshops in just a few months.",
  jobtitle: "CEO & Founder",
  company: "Anodyne Aps",
  website: "https://anodyne.dk",
  email: "kenneth@anodyne.dk",
  phone: "53 89 48 00"
},
{
  id: 2,
  image: Anne,
  name: "Anne Hill",
  description: "I had the privilege of collaborating with Munir, and I must say that his work surpassed all expectations. Every element of the website, from the layout to the color scheme, was meticulously crafted to create a visually appealing and intuitive interface and that is what we called it - Munir's touch.",
  jobtitle: "Lead project manager",
  company: "Anodyne Aps",
  website: "https://anodyne.dk",
  email: "anne@anodyne.dk",
  phone: " "
},
{
  id: 3,
  image: Art,
  name: "Art Junior Lakhampui",
  description: " I am thrilled to write a review about Munir. He designed and implemented AJRCollection Shopify Webshop. First and foremost, Munir have consistently delivered high-quality websites for Anodyne and others, that not only meet but exceed client expectations. His ability to understand and translate complex requirements into functional and visually stunning websites is a testament to his expertise and dedication.",
  jobtitle: "CEO",
  company: "AJRCollection",
  website: "https://ajrcollection.com",
  email: "artlakhampui@outlook.com",
  phone: " "
}

]