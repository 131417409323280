import React, { useState } from 'react'
import "./services.css"
const Services = () => {

  const [toggleState, setToggleState ] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index);
  }
  return (
    <section className="services section" id="services">
      <div className="container">
              <h2 className="section__title">Services</h2>
             <span className="section__subtitle">The services I offer</span>

      <div className="services__container grid">

        <div className="services__content">
          <div>
            <i className="uil uil-arrow services__icon"></i>
            <h3 className="services__title"> Ui/Ux <br /> Designer</h3>
          </div>

          <span className="services__button"  onClick={() => toggleTab(1)}>View More <i className="uil uil-arrow-right services__button-icon"></i></span>

          <div className={ toggleState === 1 ? "services__modal active__modal" : "services__modal"}>
                <div className="services__modal-content">
                    <i className="uil uil-times services__modal-close" onClick={() =>toggleTab(0)}></i>
                    <h3 className="services__modal-title">Ui/Ux Designer</h3>
                    <p className="services__modal-description">Service with more than 4 years of experience. Providing quality work to clients and companies.</p>
       

                <ul className="services__modal-services grid">
                    <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Designing and implementing user friendly interfaces.</p>
                    </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Website design using Figma, Adobe Illustrator and Adobe Photoshop.</p>
                  </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">I create UI&UX elements.</p>
                  </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Brand positioning on search ranks.</p>
                  </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Design mockups and visualising best practices.</p>
                  </li>
                </ul>
         </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i className="uil uil-edit services__icon"></i>
            <h3 className="services__title"> Visual <br /> Designer</h3>
          </div>

          <span className="services__button"onClick={() => toggleTab(2)}>View More <i className="uil uil-arrow-right services__button-icon"></i></span>

          <div className={ toggleState === 2 ? "services__modal active__modal" : "services__modal"}>
                <div className="services__modal-content">
                    <i className="uil uil-times services__modal-close" onClick={() => toggleTab(0)}></i>
                    <h3 className="services__modal-title">Visual Designer</h3>
                    <p className="services__modal-description">Service with more than 4 years of experience. Providing quality work to clients and companies.</p>
             

                <ul className="services__modal-services grid">
                    <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Develop and design user friendly interfaces.</p>
                    </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Website design and implementation.</p>
                  </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Design and create UI&UX and its best interactions.</p>
                  </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Designing mockups of products and logos for companies and private.</p>
                  </li>
                </ul>
               </div>
          </div>
        </div>
        <div className="services__content">
          <div>
            <i className=" bx bx-shopping-bag services__icon"></i>
            <h3 className="services__title"> Ecommerce design <br /> and development</h3>
          </div>

          <span className="services__button"onClick={() => toggleTab(3)}>View More <i className="uil uil-arrow-right services__button-icon"></i></span>

          <div className={ toggleState === 3 ? "services__modal active__modal" : "services__modal"}>
                <div className="services__modal-content">
                    <i className="uil uil-times services__modal-close" onClick={() => toggleTab(0)}></i>
                    <h3 className="services__modal-title">Ecommerce development</h3>
                    <p className="services__modal-description">Service with more than 4 years of experience. Providing quality work to clients and companies in Shopify.</p>
             

                <ul className="services__modal-services grid">
                    <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">I develop and design Ecommerce interface in Shopify, Wordpress, Woocommerce, React and NextJs.</p>
                    </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Ecommerce webshop design.</p>
                  </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Design and create Ecommerce UI&UX and its best interactions.</p>
                  </li>

                  <li className="services__modal-service">
                      <i className="uil uil-check-circle services__modal-icon"></i>
                      <p className="services__modal-info">Designing mockups of products, landingpages, UI elements and logos using Photoshop, Illustrator and Figma.</p>
                  </li>
                </ul>
               </div>
          </div>
        </div>

      </div>
      </div>
    </section>
  )
}

export default Services