const Logo = () => {
  return (
    <svg className="logo__container" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 400 400">
        <g>
          <path class="em one" d="M179.7,147.3c-2.4-3.2-7.3-3.1-9.6,0.1l-13.9,19.4c-1.5,2.1-1.5,5,0.1,7l52.3,68.3c2.3,3,6.9,3,9.4,0
            l13.4-17.2c1.6-2.2,1.7-5.1,0.2-7L179.7,147.3z"/>
          <path class="em two" d="M153,183.1c-2.4-3-6.9-3-9.4,0l-21.4,27.4c-3.1,3.9-0.3,9.6,4.6,9.6h42.5c4.9,0,7.6-5.7,4.7-9.6L153,183.1z"/>
          <path class="em three" d="M231.5,146.8c-2.3-3.1-7-3.2-9.5-0.1l-13.8,17.6c-1.6,2.1-1.7,5.1-0.1,7.2l34,46.2c1,1.5,2.9,2.4,4.7,2.4h26.2
            c4.9,0,7.6-5.4,4.9-9.5L231.5,146.8z"/>
          <path class="circle" d="M200,15.5c-97.8,0-177,79.2-177,177s79.2,177,177,177s177-79.2,177-177S297.8,15.5,200,15.5z M169.4,220.1
            h-42.5c-5,0-7.7-5.7-4.6-9.6l21.4-27.4c2.4-3,6.9-3,9.4,0l21.1,27.4C177,214.4,174.2,220.1,169.4,220.1z M231.4,224.9L218,242.2
            c-2.4,3-7,3-9.4,0l-52.3-68.3c-1.6-2.1-1.6-5-0.1-7l13.9-19.4c2.3-3.2,7.2-3.4,9.6-0.1l52,70.6C233.2,219.9,233,222.7,231.4,224.9z
            M273.1,220.1h-26.2c-1.8,0-3.7-0.9-4.7-2.4l-34-46.2c-1.6-2.1-1.5-5.1,0.1-7.2l13.8-17.6c2.4-3.1,7.2-3,9.5,0.1l46.5,63.8
            C280.8,214.7,278,220.1,273.1,220.1z"/>
        </g>
    </svg>
  )
}

export default Logo