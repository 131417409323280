import React from 'react'
import './about.css';
import cv from "../../assets/munir-cv-en.pdf";
import MySignature from '../utils/MySignature';
const About = () => {
  return (
    <div className="about section" id="about">
      <div className="container">
      <h2 className="section__title">Who am I?</h2>
      <span className="section__subtitle">My introduction</span>
      <p className="about__description">I am a professional web developer, UI/UX designer and Shopify developer with a passion for Front-end development. 
      My experience extends from Figma design to HTML5 , CSS and JavaScript(Reactjs, Nextjs) to  PHP, liquid, and finally to a fully functioning  CMS platforms in WordPress, and Shopify.</p>
      <span className="about__signature"><code><MySignature /></code></span>
        
        <div className="cv__container">
 <a href={cv} className="button cv__button" target={'_blank'} rel={'noreferrer'}>Download my CV</a>
        </div>
      </div>
    </div>
  )
}

export default About