import React from 'react'
// import HandImoji from '../utils/HandImoji'
import HammerAndRench from '../../assets/hammerandrench3d.png'
import SendIcon from '../utils/SendIcon'

const Data = () => {
  return (
    <>
        <h1 className="home__title"><span className="text">WEB HANDYMAN</span>
        <img className='hammer_rench' alt='handyman tools' src={HammerAndRench} />
    </h1>
    <h3 className="home__subtitle">
      Web Developer
    </h3>
      <p className="home__description">
      I'm passionate and dedicated web and frontend developer based in Aarhus, Denmark.
      </p>
      <a href="#contact" className="button button--flex">
        Say Hi 
        <SendIcon />
      </a>
    </>
  )
}

export default Data