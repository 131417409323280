import React, {useEffect, useState} from 'react'
import {projectsList, projectsCategory } from './Data';
import WorkItems from './WorkItems';


const Works = () => {
  const [item, setItem] = useState({ name: 'all'});
  const [porjectCategories, setProjectCategories] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(()=>{
    if(item.name === "all"){
      setProjectCategories(projectsList);
    } else {
      const newProjectCategory = projectsList.filter((projectCategoryItem) =>{
      return projectCategoryItem.category === item.name;
    });
    setProjectCategories(newProjectCategory)
    }
  }, [item]);

  const handleClick = (e, i) => {
  setItem({name: e.target.textContent });
  setActive(i);
  }
  return (
    <div className="work">
        <div className="work__filters">
          {projectsCategory.map((item, i) => {
            return <span onClick={(e) => {
              handleClick(e,i);
            }} className={`${active === i ? 'work__item-active' : '' } work__item`} key={item.id}>{item.name}</span>
            })}
      </div>
      <div className="work__container grid">
           {porjectCategories.map((item, i)=>{
            return <WorkItems item={item} key={item.id} />
        })}
      </div>
    </div>
  )
}

export default Works