import "./App.css"
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Qualifications from "./components/qualifications/Qualifications";
import Scrollup from "./components/scrollup/Scrollup";
import Services from "./components/services/Services";
import Skills from "./components/skills/Skills";
import Testimonials from "./components/testimonails/Testimonials";
import Work from "./components/work/Work";

function App() {
  return (
    <div className="App">
    <Header />
    <main className="main">
    <Home />
    <About />
    <Skills />
    <Services />
    <Qualifications />
    <Work />
    <Testimonials />
    <Contact />
    </main>
    <Footer />
    <Scrollup />
    </div>
  );
}

export default App;
