import React from 'react'

function TopContent() {
  return (
      <div className="contact__content">
          <div className="contact__info">
            <div className="contact__card">
                <i className="bx bx-mail-send contact__card-icon"></i>
                <h3 className="contact__card-title">Email</h3>
                <span className="contact__card-data">amuniry@gmail.com</span>
                <a href="mailto:amuniry@gmail.com" className="contact__button">Write me <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
            </div>

            <div className="contact__card">
                <i className="bx bx-mobile-alt contact__card-icon"></i>
                <h3 className="contact__card-title">Call me</h3>
                <span className="contact__card-data">+456066066</span>
                <a href="tel:+456066066" className="contact__button">Call me <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
            </div>

          </div>
      </div>
  )
}

export default TopContent