import React from 'react'

const backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Backend Development</h3>

      <div className="skills__box">
          <div className="skills__group">
              <div className="skills__data">
                    <i className="bx bx-check-square"></i>
                    <div>
                    <h3 className="skills__name">PHP</h3>
                      <span className="skills__level">Advance</span>
                    </div>
              </div>

              <div className="skills__data">
                    <i className="bx bx-check-square"></i>
                    <div>
                    <h3 className="skills__name">LIQUID</h3>
                      <span className="skills__level">Advance</span>
                    </div>
              </div>

              <div className="skills__data">
                    <i className="bx bxl-nodejs"></i>
                    <div>
                    <h3 className="skills__name">NODE JS</h3>
                      <span className="skills__level">Intermediate</span>
                    </div>
              </div>

              <div className="skills__data">
                    <i className="bx bx-check-square"></i>
                    <div>
                    <h3 className="skills__name">MYSQL</h3>
                      <span className="skills__level">Basic</span>
                    </div>
              </div>

          </div>
          <div className="skills__group">

              <div className="skills__data">
                    <i className="bx bx-check-square"></i>
                    <div>
                    <h3 className="skills__name">ExpressJS</h3>
                      <span className="skills__level">Basic</span>
                    </div>
              </div>
              <div className="skills__data">
                    <i className="bx bx-check-square"></i>
                    <div>
                    <h3 className="skills__name">Firebase</h3>
                      <span className="skills__level">Advance</span>
                    </div>
              </div>
              <div className="skills__data">
                    <i className="bx bx-check-square"></i>
                    <div>
                    <h3 className="skills__name">MongoDB</h3>
                      <span className="skills__level">Basic</span>
                    </div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default backend