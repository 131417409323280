function InputField(props) {
const {handleChange, label, name, type, placeholder, value } = props;

  return (
      <div className="contact__form-div">
        <label className="contact__form-tag">{label}</label>
        <input onChange={handleChange} type={type} name={name} placeholder={placeholder} value={value} className="contact__form-input" required />
    </div>
  )
}

export default InputField