import React, { useState } from 'react';


const WorkItems = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const bodyModal = () => {
    setIsOpen(false);
  }
  return (
    <div className="work__card" key={item.id}>
          <img src={item.cover} alt={item.title} className="work__img" />
          <div className="work__inner">
          <h3 className="work__title">{item.title}</h3>
          {/* <p className="work__description">{item.description}</p> */}
          { item.pageUrl ? <a href={item.pageUrl} className='work__button'  target='_blank' rel='noreferrer'> Visit website <i className='bx bx-right-arrow-alt work__button-icon'></i></a> :  <div>
      <button  className='work__button' onClick={openModal}>Zoom <i className='bx bx-plus work__button-icon'></i> </button>
      {isOpen && (
        <div className="modal" onClick={bodyModal}>
          <div className="container">
          <div className="modal__content">
            <span className="modal__close" onClick={closeModal}>&times;</span>
            <h2>{item.title}</h2>
            <p>{item.description}</p>
            <img src={item.image} alt={item.title} className="modal__img" />
            {item.image2 ?   <img src={item.image2 } alt={item.title} className="modal__img" /> : ''}
          </div>
          </div>
        </div>
      )}
    </div>}
          </div>
    </div>
  )
}

export default WorkItems