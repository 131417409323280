import React, {useEffect, useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './contact.css'
import InputField from './InputField';
import TextareaField from './TextareaField';
import SendIcon from '../utils/SendIcon';
import TopContent from './TopContent';

const ContactForm = () => {
const form = useRef();
  const [values, setValues] = useState({
    name: '',
    email: '',
    project: ''
  });
const [status, setStatus ] = useState('');
const handleSubmit = (e) => {
  e.preventDefault();
  emailjs.sendForm('service_khvfy6i', 'template_dgxin9x', form.current,'8QNsYvQMX41QV4AV0')
      .then((result) => {
          setStatus('SUCCESS', result);
          setValues({
            name: '',
            email: '',
            project: ''
            })
      }, (error) => {
          console.log(error.text);
      });

}
useEffect(() =>{
  if(status === 'SUCCESS'){
      setTimeout(() =>{
        setStatus('');
    },8000);
  }
}, [status]);

  const handleChange = (e) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }
  return (
    <section className="contact section" id="contact" >
         <div className="container">
            <h2 className="section__title">Get in touch </h2>
             <span className="section__subtitle">You have a project then lets talk.</span>
            {status && renderAlert()}
              <div className="contact__container grid">
                        <TopContent />
                    <div className="contact__content">
                      <form className="contact__form" ref={form} onSubmit={handleSubmit}>
                          <InputField value={values.name} handleChange={handleChange}  label="Full Name" name="name" type="text" placeholder="Insert your name ..." />

                          <InputField value={values.email} handleChange={handleChange} label="Your Email" name="email" type="email" placeholder="Insert your email ..." />

                          <TextareaField value={values.project} handleChange={handleChange} name='project' placeholder="Write your message" label="Your Message" />
                            <button className="button button--flex" type='submit' value="send">
                              Send Message
                              <SendIcon />
                            </button>
                      </form>

                    </div>
              </div>
      </div>
    </section>
  )
}
const renderAlert = () => (
<div className="alert__wrapper">
 <span className="message_alert"><bold>Thank You !</bold><br />I have recieved your message, and I will be in touch soon.</span>
</div>
)
export default ContactForm