import React, { useState } from 'react'
import "./qualifications.css"
const Qualifications = () => {
  const [toggleState, setToggleState ] = useState(1);

  const toggleTab = (index) => { setToggleState(index)};
  return (
    <section className="qualification section" id="qualification">
      <div className="container">
              <h2 className="section__title">Qualifications</h2>
             <span className="section__subtitle">My personal journey</span>

      <div className="qualification__container">
        <div className="qualification__tabs">
          <div className={ toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(1)}>
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>
            <div className={ toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}onClick={() => toggleTab(2)}>
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
            </div>
        </div>
        <div className="qualification__sections">
          <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
            <div className="qualification__data">
              <div>
                    <h3 className="qualification__title">Graphic Design and Wordpress</h3>
                    <span className="qualification__subtitle">Future Campany Aarhus, Denmark</span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>
                        <span className="date">2013 - 2014</span>
                    </div>
              </div>
              <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
            <div></div>
              <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
              </div>

              <div>
                    <h3 className="qualification__title">Multimedia Design and Web development</h3>
                    <span className="qualification__subtitle">Business Academy Aarhus, Denmark</span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>
                      <span className="date">2014 - 2016</span>
                    </div>
              </div>
            </div>
            <div className="qualification__data">
              <div>
                    <h3 className="qualification__title">Web Development</h3>
                    <span className="qualification__subtitle">Business Academy Aarhus, Denmark</span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>
                      <span className="date">2016 - 2017</span>
                    </div>
              </div>
              <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
              </div>
            </div>
          </div>

          <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
            <div className="qualification__data">
              <div>
                    <h3 className="qualification__title">Graphic Design and Wordpress</h3>
                    <span className="qualification__subtitle">Future Campany Aarhus, D222enmark</span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>
                      <span className="date">2013 - 2014</span>
                    </div>
              </div>
              <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
            <div></div>
              <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
              </div>

              <div>
                    <h3 className="qualification__title">Wordpress developer</h3>
                    <span className="qualification__subtitle">PR Marketing, Horsens</span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>
                      <span className="date">2016 - 2016</span>
                    </div>
              </div>
            </div>
            <div className="qualification__data">
              <div>
                    <h3 className="qualification__title">Umbraco CMS developer </h3>
                    <span className="qualification__subtitle">Kraftvaerk, Aarhus</span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>
                     <span className="date"> 2018 - 2019</span>
                    </div>
              </div>
              <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
              <div></div>
              <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
              </div>

              <div>
                    <h3 className="qualification__title">Smart web and Shopify ecommerce developer</h3>
                    <span className="qualification__subtitle">Anodyne Aps</span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>
                      <span className="date">2019 - Present</span>
                    </div>
              </div>
            </div>
          </div>

        </div>


      </div>
      </div>
    </section>
  )
}

export default Qualifications