import React from 'react'

function MySignature() {
  return (
<svg viewBox="0 0 1318.8 946.7" className='mysignature'>
<g>
	<g>
		<g>
			<path d="M21.1,144.8c23.6-8.6,50.6-5.4,71.2,9.2c20.6,14.5,33,37.9,42.7,60.6c21.3,50.1,29,104.9,29.3,159
				c0.2,25.7-0.8,51.6-4,77.1c-2.8,22.4-8.1,46.1-22.2,64.3c-7.4,9.5-17.2,17.7-29,20.9c-6.1,1.6-12.6,1.8-18.6-0.5s-10.8-7-15-11.7
				c-15.1-17-23.8-38.5-27.5-60.7c-8.1-49.5,8-98,28-142.5c11-24.4,23-48.4,33.3-73.1c10.4-25,21-52.5,19.8-80
				c-1-21.6-11.4-44.6-31.8-54.1c-8.8-4.1-19.9-5.8-25.7,3.8c-5.2,8.7-4,20.6-3.8,30.3c1.3,49,12.2,97.6,31.4,142.7
				c9.7,22.8,21.5,44.6,35.3,65.2c6.8,10.1,14,19.8,21.7,29.2c7.8,9.5,15.8,19.2,25,27.4c7.8,7,17.6,12.7,28.5,10.4
				c8.7-1.8,15.1-8.6,19.2-16.1c4.6-8.5,6.9-18.1,8.1-27.6c1.8-14.6,2.5-29.4,2.4-44.2c-0.1-14.6-1-29.1-2.8-43.6
				c-0.4-3.1-5.2-3.3-5,0c3.2,54.9,7,111.6,29,162.7c6.3,14.5,14.1,28.3,23.5,40.9c1.3,1.8,3.8,1.5,4.6-0.6
				c21.6-59.2,33.4-121.8,34.7-184.8c0.4-18-0.1-35.9-1.4-53.8c-0.2-3.2-5-3.3-5,0c-0.2,48.7,0.6,98.4,13.8,145.6
				c3.2,11.5,7.2,22.7,12,33.6c4.2,9.6,8.8,20.1,16.7,27.2c14.5,13,35,7.3,47.2-5.9c17.9-19.4,13-48.3,8.5-71.8
				c-1.6,0.4-3.2,0.9-4.8,1.3c11.2,29.3,22.4,58.5,33.6,87.8c0.7,1.8,3.5,2.7,4.6,0.6c23.4-47.7,38.5-99,44.7-151.8
				c-1.6,0.4-3.1,0.8-4.7,1.3c5,11.6,10.5,23.1,16.6,34.2c4.5,8.2,9.3,18.9,18.8,22.3c8.7,3.1,17.8-1.7,22.9-8.7
				c6.7-9.1,6.2-21.3,5.7-32c-1.7,0-3.3,0-5,0c2.7,43,14,85.1,33.2,123.7c1.1,2.1,3.9,1.2,4.6-0.6c10-25.8,20.1-51.6,30.1-77.4
				c-1.6,0-3.2,0-4.8,0c6.8,21.9,13.7,43.9,20.5,65.8c0.6,2,3.5,2.5,4.6,0.6c11-19.6,22-39.2,33-58.8c2.4-4.3,13.2-29.1,20.1-19.8
				c3.1,4.2,5,9.7,7.3,14.3c2.6,5.2,5.2,10.4,7.8,15.6c0.9,1.8,3.4,1.5,4.3,0c9.4-14.3,14.2-31,14-48.1c0-3.2-4.8-3.2-5,0
				c-1.9,31.3,3.2,62.7,14.8,91.9c0.8,2,4.4,2.7,4.8,0c5.2-32.3,15.1-63.7,29.5-93.1c-1.4,0-2.9,0-4.3,0
				c9.8,13.2,16.2,28.6,18.6,44.8c1.2,8,1.4,16.1,0.6,24.1c-0.6,6.6-1.8,15.3-7.2,19.8c-7.6,6.3-16.6-1.6-18.8-9.2
				c-2.6-9.1,0-19.4,1.4-28.5c2.7-18,5.8-36,9.3-53.9c14.2-72.7,34.1-144.1,56.1-214.8c12.4-40,25.5-79.8,38.6-119.6
				c1-3.1-3.8-4.4-4.8-1.3c-25.2,76.5-50.4,153.1-70.9,231c-10.2,38.9-19.3,78.1-26.6,117.6c-3.5,18.9-7.6,38.2-9,57.4
				c-0.6,8,0.4,16.6,6.2,22.7c4.5,4.8,11.5,7.4,17.8,4.7c6.8-2.9,10-10.2,11.5-17c2-8.9,2.2-18.4,1.4-27.5
				c-1.7-19.1-8.5-37.4-19.9-52.8c-1-1.4-3.4-1.9-4.3,0c-14.6,29.8-24.7,61.5-30,94.3c1.6,0,3.2,0,4.8,0
				c-11.4-28.8-16.4-59.6-14.6-90.6c-1.7,0-3.3,0-5,0c0.2,16.3-4.4,31.9-13.3,45.5c1.4,0,2.9,0,4.3,0c-3.1-6.2-6.2-12.4-9.3-18.6
				c-2.3-4.5-4.2-10.6-8.2-14c-8.6-7.1-16.3,4.5-19.9,11c-13.1,23.4-26.3,46.8-39.4,70.2c1.5,0.2,3,0.4,4.6,0.6
				c-6.8-21.9-13.7-43.9-20.5-65.8c-0.8-2.5-3.9-2.2-4.8,0c-10,25.8-20.1,51.6-30.1,77.4c1.5-0.2,3-0.4,4.6-0.6
				c-18.8-37.8-29.8-79.1-32.5-121.2c-0.2-3.2-5.2-3.2-5,0c0.5,9.4,1.3,20.3-4.3,28.5c-5.3,8-15.5,11.2-23,4.1
				c-6.3-6-10.2-15.3-14.2-23c-4.8-9.1-9.2-18.4-13.2-27.9c-1.1-2.6-4.4-0.9-4.7,1.3c-6.1,51.8-21.1,102.4-44,149.3
				c1.5,0.2,3,0.4,4.6,0.6c-11.2-29.3-22.4-58.5-33.6-87.8c-1.1-3-5.4-1.9-4.8,1.3c3.9,20.4,8.8,45.9-4.7,64
				c-5.2,6.9-12.7,12.3-21.1,14.3c-9.6,2.2-18.4-1.3-24.7-8.7c-7-8.2-11.4-19.1-15.4-29s-7.3-20.1-10-30.5
				c-5.7-21.6-8.8-43.8-10.5-66c-1.9-24.6-2.1-49.4-2-74.1c-1.7,0-3.3,0-5,0c4.5,62.6-1.3,125.9-17.3,186.6
				c-4.5,17.1-9.8,34-15.9,50.6c1.5-0.2,3-0.4,4.6-0.6c-33-44.5-43.6-100.2-48.5-154.2c-1.4-15.6-2.4-31.2-3.3-46.9
				c-1.7,0-3.3,0-5,0c3.5,29,3.9,58.7,0.3,87.8c-2.2,17.6-13.1,48-36.6,37c-10.9-5.1-19.3-15.8-27.1-24.7
				c-8.3-9.5-16.1-19.4-23.4-29.7c-14.2-20-26.5-41.3-36.7-63.6C87.5,252.7,75.4,204,73.2,154.8c-0.3-6.2-0.4-12.4-0.4-18.5
				c0-5.1,0.2-10.5,2.4-15.2c5.9-12.8,22.8-3.1,30.1,3.4c20.1,17.9,21.6,46.5,16.1,71.2c-5.9,26.9-17.3,52.6-28.8,77.5
				c-11.2,24.3-23.1,48.3-32.8,73.3C43.3,388.8,34.1,436.6,47,481.2c5.8,19.9,16.8,41.5,33.6,54.2c19.4,14.6,44,2.7,58.2-13.4
				c14.5-16.4,21.2-38.1,24.8-59.3c4.1-24.4,5.2-49.5,5.6-74.1c0.9-52.8-4.5-106.5-22.2-156.5c-8.1-22.9-18.2-46.2-33.8-65.1
				c-15.1-18.2-36.1-30.1-59.9-31.7C42,134.4,30.5,136,19.8,140C16.8,141.1,18.1,145.9,21.1,144.8L21.1,144.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path d="M635,165.3c3.2,0,3.2-5,0-5S631.7,165.3,635,165.3L635,165.3z"/>
		</g>
	</g>
	<g>
		<g>
			<path d="M594.6,565.8c20.5,1.3,40.6-5.7,57.5-16.8c17.5-11.5,31.6-27.2,43.9-44c7.2-9.8,13.9-20,20.5-30.2
				c-1.6-0.4-3.1-0.8-4.7-1.3c-4.6,61.6-6.7,123.4-6.3,185.2c0.5,61.8,3.5,123.6,9,185.1c3.1,34.6,7,69,11.7,103.4
				c0.4,3.2,5.3,1.8,4.8-1.3c-8.4-61.3-14.2-123-17.5-184.8c-3.3-61.4-4-122.9-2.2-184.4c1-34.5,2.8-68.9,5.4-103.3
				c0.2-2.6-3.4-3.2-4.7-1.3c-21.8,33.4-45.9,69.9-85.3,83.8c-10.4,3.7-21.3,5.4-32.3,4.7C591.4,560.6,591.4,565.6,594.6,565.8
				L594.6,565.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path d="M808.9,343.7c3.2,0,3.2-5,0-5S805.7,343.7,808.9,343.7L808.9,343.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path d="M803,341.2c1,40.5,7.9,80.8,20.5,119.3c2.4,7.2,4.7,15.4,10.4,20.8c5.5,5.3,13.3,5.4,19.3,0.8
				c6.6-5.1,10.7-13.6,14.4-20.9c4.2-8.3,7.6-16.9,10.3-25.8c6-19.9,8.1-40.9,6-61.6c-0.3-3.1-5-3.3-5,0c-0.1,44.9,1.6,89.7,5,134.4
				c1.7,22.2,3.9,44.4,6.5,66.5c1.3,11,2.7,21.9,4.2,32.8c0.8,5.5,1.5,11,2.4,16.5c0.8,5.3,2,10.7,2,16c0,4.3-1.1,8.7-4.6,11.4
				c-2.7,2.1-6.3,3-9.7,3c-8.5,0-15-5.6-19.4-12.5c-5-7.9-8-17.1-10.5-26.1c-2.6-9.2-4.4-18.5-5.4-28c-4.1-38.6,4-77.5,17.8-113.5
				c7.1-18.5,16-36,25.6-53.3c9.4-16.9,19.4-35,20-54.8c0.2-4.8-0.3-9.5-1.4-14.2c-0.6-2.5-4.1-2.3-4.8,0
				c-8.2,26.7-4.5,56.6,9.3,80.8c3.9,6.9,8.7,13.3,14.1,19.1c0.8,0.9,2.7,1,3.5,0c10.2-13.2,16.5-28.7,20.4-44.9
				c4-16.3,6.3-33.3,7.8-50c1.2-14.1-0.6-31.7-15.7-37.7c-5.3-2.1-11.7-2.4-17,0c-5.6,2.6-8.8,8.7-8.1,14.8
				c0.9,8,7.4,14.7,14.7,17.3c10.1,3.6,21.3,0.3,31-2.6c-1.1-0.8-2.1-1.6-3.2-2.4c-4.4,34.2,1.3,69.2,16.1,100.3
				c0.8,1.7,3.5,1.6,4.3,0c16.1-32.5,28.8-66.6,37.6-101.8c2.5-10,4.7-20,6.6-30.1c0.5-2.6-3.3-4.7-4.6-1.9
				c-10.3,23.2-9.4,50.5,2.6,72.9c0.7,1.3,2.9,1.8,3.9,0.5c19.6-24.5,33.5-53.3,40.3-84c-1.6-0.2-3.3-0.4-4.9-0.7
				c-1.2,27.3-2.5,54.5-3.7,81.8c-0.1,2.9,4,3.2,4.9,0.7c12.2-36.3,25.1-72.4,38.5-108.3c-1.4,0.4-2.8,0.7-4.2,1.1
				c7.8,9.8,9.3,22.5,8.9,34.6c-0.4,13-2.6,26-2.6,39c0,7.2,0.7,14.5,2.6,21.4c0.6,2.1,3.4,2.4,4.6,0.6
				c18.2-28.2,33.6-58.1,45.9-89.4c3.5-8.8,6.7-17.8,9.7-26.8c1-2.9-3-4-4.6-1.9c-17.2,22.6-21.4,54.2-10.7,80.5
				c0.7,1.7,2.7,2.6,4.2,1.1c24.9-24.9,45.4-54.1,60.3-86.1c3.1-6.7,6.6-13.7,6.8-21.3c0.2-6.9-3.5-13.7-10.7-15.2
				c-7.7-1.5-15.3,3-21,7.8c-6.1,5.1-11.5,11.1-16.2,17.5c-10.7,14.8-17,32.5-18.3,50.7c-0.1,1.4,1.2,2.4,2.5,2.5
				c35.8,1.8,70.7-19.5,87.9-50.5c4.9-8.8,8.3-18.4,9.9-28.3c0.2-1.3-0.3-2.8-1.7-3.1c-7.9-1.8-13.1,6.6-12,13.6
				c0.6,4.2,2.8,7.9,4.8,11.5c2.1,3.8,3.8,7.8,5.1,12c5.4,17.1,3.9,35.6,0.4,53c-3.7,18.6-9.5,36.8-15.5,54.7
				c-6.4,18.9-13.5,37.6-21.5,55.8c-4.4,10-9,20-13.9,29.8c-4,8-8.5,16.5-17.1,20.3c1.3,0.7,2.5,1.4,3.8,2.2
				c-2.4-34.4,9.8-67.6,26.2-97.3c16.9-30.7,38.4-58.4,55.9-88.8c7.4-12.9,20.4-32.8,9.8-47.3c-1.1,1.1-2.3,2.3-3.4,3.4
				c11.3,7.3,17.5,20.1,19.4,33.1c2.5,16.5-1.3,33.4-4.8,49.5c-7,32.4-15.6,64.4-25.7,96c-5.7,17.7-11.9,35.3-18.5,52.7
				c-1.1,3,3.7,4.3,4.8,1.3c12.2-32,22.8-64.6,31.8-97.6c4.5-16.6,8.6-33.3,12.2-50.1c3.6-16.6,7.5-33.8,5.3-50.9
				c-1.9-15.1-9-29.8-22-38.2c-2-1.3-4.9,1.4-3.4,3.4c4.5,6.1,3.5,13.6,1.2,20.4c-2.6,7.7-6.9,14.8-11,21.9
				c-8.6,14.9-18.2,29.3-27.7,43.6c-18.8,28.3-37.5,57.1-47.8,89.7c-5.7,17.8-8.6,36.6-7.3,55.3c0.1,2,2,2.9,3.8,2.2
				c7.1-3.1,12-9.4,15.7-16c4.5-7.9,8.2-16.4,12-24.7c8-17.5,15.4-35.4,21.9-53.5c6.5-18,12.4-36.2,17.2-54.8
				c4.4-16.8,7.7-34.4,6.4-51.8c-0.7-9.5-2.9-18.8-6.9-27.5c-2-4.2-5-8.2-6.1-12.8c-0.8-3.4,0.7-10.1,5.5-9c-0.6-1-1.2-2.1-1.7-3.1
				c-5.5,33.7-32.1,61.9-64.5,71.6c-9.2,2.8-18.8,4-28.4,3.5c0.8,0.8,1.7,1.7,2.5,2.5c1-14.8,5.7-29.2,13.4-41.9
				c3.8-6.2,8.3-12,13.4-17.1c5-5.1,11-10.6,18.2-12c3.3-0.6,6.8,0.1,9,2.8c1.9,2.4,2.2,5.5,2,8.4c-0.5,7.1-4.1,13.8-7.1,20.1
				c-6.8,14.3-14.8,28.1-23.8,41.1c-10.3,14.9-21.9,28.8-34.7,41.6c1.4,0.4,2.8,0.7,4.2,1.1c-10.3-25.2-6.3-54.9,10.2-76.6
				c-1.5-0.6-3-1.3-4.6-1.9c-10.4,31.6-23.9,62.1-40.4,91c-4.6,8.1-9.5,16.1-14.6,24c1.5,0.2,3,0.4,4.6,0.6
				c-7.3-25.8,4.3-52.5-1.3-78.4c-1.5-6.9-4.5-13.3-8.9-18.9c-1.3-1.6-3.6-0.5-4.2,1.1c-13.4,35.9-26.2,72-38.5,108.3
				c1.6,0.2,3.3,0.4,4.9,0.7c1.2-27.3,2.5-54.5,3.7-81.8c0.1-2.8-4.3-3.4-4.9-0.7c-6.6,29.8-20,57.9-39.1,81.8
				c1.3,0.2,2.6,0.3,3.9,0.5c-11.1-20.7-12.2-46.4-2.6-67.9c-1.5-0.6-3-1.3-4.6-1.9c-6.6,35.4-17,70-31,103.1
				c-3.9,9.3-8.2,18.5-12.7,27.6c1.4,0,2.9,0,4.3,0c-14.5-30.3-19.8-64.5-15.5-97.8c0.2-1.6-1.8-2.8-3.2-2.4
				c-8,2.4-16.8,5.2-25.2,3.5c-6.1-1.2-11.9-5.6-13.8-11.8c-1.5-5,0.7-10.3,5.6-12.2c4.5-1.8,10-0.9,14.2,1.3
				c12.5,6.6,11.4,23.9,10.1,35.8c-1.6,14.4-3.5,28.9-6.8,43.1c-3.7,15.9-9.5,31.6-19.6,44.7c1.2,0,2.4,0,3.5,0
				c-18.3-19.4-27.6-46.4-26.1-73c0.4-7.5,1.8-14.9,4-22.1c-1.6,0-3.2,0-4.8,0c4.6,20.6-4.9,40.8-14.5,58.5
				c-9.7,17.8-19.4,35.5-27.1,54.3c-15,36.7-24.3,76.8-21.1,116.6c1.5,19,5.6,38.8,14,56.1c3.7,7.7,9.3,15.2,17.4,18.6
				c6.7,2.8,15.3,2.7,21.4-1.6c7.9-5.6,7.8-15.2,6.6-23.8c-1.6-11.4-3.4-22.7-4.9-34.1c-3-23-5.6-46.2-7.7-69.3
				c-4.1-45.8-6.4-91.8-6.7-137.8c0-5.7-0.1-11.5,0-17.2c-1.7,0-3.3,0-5,0c1.7,17.2,0.6,34.7-3.5,51.5
				c-3.9,16.3-10.4,32.9-19.9,46.8c-3.8,5.5-10.5,11.5-17.2,6.5c-5.8-4.5-7.9-12.9-10-19.5c-5.8-17.8-10.4-36-13.8-54.4
				c-3.8-21-6-42.2-6.5-63.6C807.9,338,802.9,338,803,341.2L803,341.2z"/>
		</g>
	</g>
	<g>
		<g>
			<path d="M1264,139.7c3.2,0,3.2-5,0-5S1260.8,139.7,1264,139.7L1264,139.7z"/>
		</g>
	</g>
</g>
</svg>
  )
}

export default MySignature