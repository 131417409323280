import React from 'react'
import "./home.css";
import Social from "./Social";
import Data from "./Data";
import ScrollDown from './ScrollDown';
import {CanvasBulbs} from '../canvas/CanvasBulbs';

function Home() {
  return (
  <section className="home section" id="home">
    <div className="home__container container for__canvas">
      <div className="home__content grid">
        <Social />
        <Data />
      </div>
    < ScrollDown />
    </div>
   <CanvasBulbs />
  </section>
  )
}

export default Home