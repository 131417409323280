import React, {useState} from 'react'
import "./header.css";
import Logo from './Logo';
import ukFlag from '../../assets/uk-flag.png';
import dkFlag from '../../assets/dk-flag.png';

function Header() {
// Menu toggle 
const [toggle, showMenu] = useState(false);
//active navlink
const [activeNav, setActiveNav] = useState("#home");

// change header background 
  window.addEventListener("scroll", function() {
          const scrollUp = document.querySelector(".header");
          if(this.scrollY >= 50 ) scrollUp.classList.add("sticky__head"); 
          else scrollUp.classList.remove("sticky__head");
  })


  return (
    <header className="header">
      <nav className="nav container">
        <a href="index.html" className="nav__logo">
          <Logo />
        <span className="logo__content">R</span></a>
        <div className={toggle ? "nav__menu show__menu" : "nav__menu"}>
          <ul className="nav__list grid">
            <li className="nav__item">
                <a href="#home" onClick ={() => setActiveNav("#home")} className={ activeNav === "#home" ? "nav__link active__link" : "nav__link"}>
                         <i className="uil uil-estate nav__icon"></i>Home
                </a>
            </li>

            <li className="nav__item">
                <a href="#about" onClick ={() => setActiveNav("#about")} className={ activeNav === "#about" ? "nav__link active__link" : "nav__link"}>
                         <i className="uil uil-user nav__icon"></i>About
                </a>
            </li>

            <li className="nav__item">
                <a href="#skills" onClick ={() => setActiveNav("#skills")} className={ activeNav === "#skills" ? "nav__link active__link" : "nav__link"}>
                         <i className="uil uil-file-alt nav__icon"></i>Skills
                </a>
            </li>

            <li className="nav__item">
                <a href="#services" onClick ={() => setActiveNav("#services")} className={ activeNav === "#services" ? "nav__link active__link" : "nav__link"}>
                         <i className="uil uil-briefcase-alt nav__icon"></i>Services
                </a>
            </li>
  
            <li className="nav__item">
                <a href="#qualification" onClick ={() => setActiveNav("#qualification")} className={ activeNav === "#qualification" ? "nav__link active__link" : "nav__link"}>
                         <i className="uil uil-graduation-cap nav__icon"></i>Qualifications
                </a>
            </li>
            <li className="nav__item">
                <a href="#portfolio" onClick ={() => setActiveNav("#portfolio")} className={ activeNav === "#portfolio" ? "nav__link active__link" : "nav__link"}>
                         <i className="uil uil-scenery nav__icon"></i>Portfolio
                </a>
            </li>

            <li className="nav__item">
                <a href="#contact" onClick ={() => setActiveNav("#contact")} className={ activeNav === "#contact" ? "nav__link active__link" : "nav__link"}>
                         <i className="uil uil-message nav__icon"></i>Contact
                </a>
            </li>
            <li className="nav__item">
                <div className="nav__link">
                       <div className="flag"><img className="ukFlag" src={ukFlag} alt="En" width="22"/>
                            <p className="language_text">English</p>
                                <a href="https://webhandyman.dk/da" className="flag-down">
                                        <div className="flag-wrap"><img className="dkFlag" src={dkFlag} alt="Dk" width="22"/>
                                        <p className="language_text">Dansk</p>
                                      </div>
                                  </a>
                        </div>
                </div>
            </li>

          </ul>

        <i className="uil uil-times nav__close" onClick={()=>showMenu(!toggle)}></i>
        </div>
        <div className="nav__toggle" onClick={()=>showMenu(!toggle)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  )
}

export default Header