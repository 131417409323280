function TextareaField(props) {
  const {handleChange, label, placeholder, name, value } = props;
  return (
        <div className="contact__form-div contact__form-textarea">
          <label className="contact__form-tag">{label}</label>
          <textarea onChange={handleChange} name={name} placeholder={placeholder} cols="30" rows="20" className="contact__form-input" value={value}></textarea>
        </div>
  )
}

export default TextareaField