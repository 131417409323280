import React from 'react'
import "./testimonials.css"
import { Data } from "./Data"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {Pagination} from 'swiper';

const Testimonials = () => {
  return (
    <section className="testimonials section" id="testimonials">
      <div className="container">
                    <h2 className="section__title">Testimonials </h2>
             <span className="section__subtitle">What people says?</span>
          <Swiper className="testimonial__container"
            // install Swiper modules
            spaceBetween={20}
            loop={true}
            grabCursor={true}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
                  576: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                        },
                  768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                  },
            }}
            modules={[Pagination]}
          >
                {Data.map(({id, name, image, description, jobtitle, company, website, email, phone }) => {
                    return (
                        <SwiperSlide className="testimonial__card" key={id}>
                              <div className="testimonial__inner">
                                  <img className='testimonial__image' src={image} alt={name} />
                                  <p className="testimonial__description">{description}</p>
                               </div>
                               <div className="testimonial__content">
                                  <h3 className="testimonial__name">{name}</h3>
                                   <span className="jobtitle">{jobtitle}</span>
                                   <p>Website: <a href={website? website : ''}>{website? website : ''}</a></p>
                                   <p>Company: {company? company : ''}</p>
                                   <p>Email: <a className="email" href={"mailto:" + email}>{email}</a></p>
                                    {phone !== " " ? <p>Phone: <a href={"tel:" + phone} className="telefon">{phone}</a></p>: " "}
                              </div>
                    
                        </SwiperSlide>
                      )

                })}
          </Swiper>
      </div>
    </section>
  )
}

export default Testimonials